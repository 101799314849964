import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../components/InnerPage';

const DieNaturDesMenschen = ({ data }) => (
    <InnerPage title="Die Natur des Menschen" description="">
        <IntroHolder
            title="Die Natur des Menschen"
            subtitle="Die Natur des Menschen ist eine mit Vernunft begabte Seele"
            picture={data.introImage}
            breadcrumbs={{
                parent: ' Woran Bahá’í glauben',
                'current-item': 'Die Natur des Menschen',
            }}
        >
            <IntroQuote href="/woran-bahai-glauben/natur-des-menschen/zitate">
                Mit den Händen der Macht erschuf Ich dich, mit den Fingern der
                Kraft formte Ich dich, und Ich barg in dich das Wesen Meines
                Lichtes.
            </IntroQuote>
        </IntroHolder>
        <TwoColumns>
            <div id="content">
                <p>
                    Nach den Bahá’í-Lehren ist der Sinn unseres Lebens, Gott zu
                    erkennen und Ihm nahe zu kommen. Wir erkennen Gott durch
                    Seine Offenbarer und finden die Nähe zu Ihm, indem wir unser
                    Leben nach ihren Lehren ausrichten.
                </p>
                <div>
                    <p>
                        Unsere wahre Identität verbirgt sich in der
                        vernunftbegabten{' '}
                        <Link to="/woran-bahai-glauben/natur-des-menschen/die-seele/">
                            Seele
                        </Link>
                        . Sie entsteht im Moment der Zeugung und bildet für die
                        Dauer des diesseitigen Lebens eine Einheit mit unserem
                        Körper. Freier Wille und Verstandeskraft befähigen uns
                        dazu, uns selbst und unseren eigenen und den Fortschritt
                        der Gesellschaft voranzutragen. Dafür ist eine{' '}
                        <Link to="/gemeinsames-handeln/andacht-und-dienst/">
                            Haltung des Dienstes
                        </Link>{' '}
                        gegenüber Gott und unseren Mitmenschen notwendig. Wir
                        entwickeln uns in dieser Welt weiter bis zum Moment des
                        Todes, in dem sich die Seele vom Körper löst und die
                        ewige Reise zu ihrer weiteren Vervollkommnung fortsetzt.
                    </p>
                </div>
                <div>
                    <p>
                        Zu einem Leben der Hingabe an Gott gehören{' '}
                        <Link to="/woran-bahai-glauben/natur-des-menschen/gebet-und-meditation/">
                            Gebet
                        </Link>
                        , Meditation, Fasten, Pilgerreise und der Dienst am
                        Nächsten. Sie stärken die einzigartigen Bande zwischen
                        Gott und dem Menschen, sowohl für den Einzelnen als auch
                        für die Gemeinschaft.
                    </p>
                </div>
                <Reference>
                    <p>
                        1. Bahá&#8217;u&#8217;lláh, Verborgene Worte, Arabisch
                        12
                    </p>
                </Reference>
            </div>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default DieNaturDesMenschen;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: {
                eq: "Kachel_Die-Natur-des-Menschen-a-e1487613372304.jpg"
            }
        ) {
            ...fluidImage
        }
    }
`;
